import { useState } from 'react'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { Arrow, ArrowUpRight } from '@vhl/icons'
import { SidebarType } from '../Dropdown'
import ToggleSidebar from './ToggleSidebar'
import SearchBox from '../../SearchBox'
import { useToggle } from 'react-use'
import { navigate } from 'gatsby'
import { PAGES_SLUG } from '@/constants/pages'

interface SideBoxProps {
  title: string
  onClick?: () => void
  type?: 'toggle' | 'link'
}

function SideBox({ title, onClick, type = 'toggle' }: SideBoxProps) {
  const [isClicked, setIsClicked] = useToggle(false)
  const isToggleType = type === 'toggle'
  const sideBoxClass = classNames(
    'py-6 cursor-pointer flex flex-row group',
    { 'justify-between items-center': isToggleType },
    { 'space-x-2 items-start': !isToggleType }
  )
  const arrowClass = classNames(
    'w-9 h-9 stroke-black transition transform duration-300 translate-x-2',
    { 'opacity-0': isToggleType },
    { 'opacity-100 translate-x-0': isToggleType && isClicked },
    { '-translate-y-1': !isToggleType && isClicked }
  )

  function handleClick() {
    setIsClicked(true)
    setTimeout(() => {
      onClick && onClick()
    }, 300)
    setTimeout(() => {
      setIsClicked(false)
    }, 500)
  }

  return (
    <motion.div className={sideBoxClass} onClick={handleClick}>
      <h1 className="heading-4">{title}</h1>
      {isToggleType ? (
        <Arrow className={arrowClass} />
      ) : (
        <ArrowUpRight className={arrowClass} />
      )}
    </motion.div>
  )
}

const MobileSidebar = ({ focus, toggle }: { focus?: boolean; toggle: any }) => {
  const [sidebarType, setSidebarType] = useState<SidebarType>(null)
  return (
    <>
      <div className="px-4">
        <div className="flex items-center justify-center py-12 px-15">
          <div className="relative">
            <SearchBox focus={focus} defaultValue="" type="transparent" />
          </div>
        </div>
        <hr />
        <SideBox
          title="Tình trạng sức khỏe"
          onClick={() => setSidebarType('healthstatus')}
        />
        <hr />
        <SideBox title="Chuyên mục" onClick={() => setSidebarType('topic')} />
        <hr />
        <SideBox
          title="Về chúng tôi"
          type="link"
          onClick={() => {
            navigate(PAGES_SLUG.about)
            toggle()
          }}
        />
        <hr />
      </div>
      <AnimatePresence exitBeforeEnter>
        {sidebarType && (
          <motion.div
            initial={{ left: '100%' }}
            animate={{ left: 0 }}
            exit={{ left: '100%' }}
            transition={{ duration: 0.8 }}
            className="fixed top-0 left-0 w-full overflow-y-scroll"
          >
            <ToggleSidebar
              type={sidebarType}
              onOutsideClick={() => setSidebarType(null)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default MobileSidebar
