import { useRef } from 'react'
import { useClickAway } from 'react-use'
import HealthStatusBar from '@/components/shared/Navbar/Dropdown/HealthStatusBar'
import TopicBar from '@/components/shared/Navbar/Dropdown/TopicBar'
import { SidebarType } from '@/components/shared/Navbar/Dropdown'
import { Arrow } from '@vhl/icons'

interface ToggleSidebarProps {
  type: SidebarType
  onOutsideClick: () => void
}

const ToggleSidebar = ({ type, onOutsideClick }: ToggleSidebarProps) => {
  const ref = useRef(null)
  useClickAway(ref, onOutsideClick)

  function BackButton() {
    return (
      <div className="flex items-center space-x-2" onClick={onOutsideClick}>
        <Arrow width={32} height={32} stroke="#FFFFFF" />
        <p>Quay lại</p>
      </div>
    )
  }

  return (
    <div className="w-full h-full" ref={ref}>
      {type === 'healthstatus' && (
        <HealthStatusBar
          split={18}
          className="flex flex-col px-4 py-4 pb-16 space-y-8"
          contentClassName="px-4"
        >
          <BackButton />
          <HealthStatusBar.Header headerClassName="heading-4" />
        </HealthStatusBar>
      )}
      {type === 'topic' && (
        <TopicBar className="px-4 py-4 pb-16 space-y-8">
          <BackButton />
          <TopicBar.Header heading="heading-4" />
          <div className="flex flex-col px-4 space-y-12">
            <TopicBar.Series seriesSplit={10} />
            <TopicBar.MainTopic
              className="flex flex-col space-y-4"
              topicClassName="flex flex-col space-y-4"
            />
            <TopicBar.OtherTopics />
          </div>
        </TopicBar>
      )}
    </div>
  )
}

export default ToggleSidebar
